// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown input {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    height: 44px;
    border: 1px solid #ddd;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
    max-height: 200px; 
    overflow-y: auto; 
    
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }`, "",{"version":3,"sources":["webpack://./src/components/PromptLibrary.css"],"names":[],"mappings":";;EAEE;IACE,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,eAAe;IACf,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;IACtB,UAAU;IACV,iBAAiB;IACjB,gBAAgB;;EAElB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["\n  \n  .dropdown {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .dropdown input {\n    box-sizing: border-box;\n    width: 100%;\n    padding: 12px;\n    font-size: 16px;\n    height: 44px;\n    border: 1px solid #ddd;\n  }\n  \n  .dropdown-content {\n    display: block;\n    position: absolute;\n    background-color: #f6f6f6;\n    min-width: 230px;\n    border: 1px solid #ddd;\n    z-index: 1;\n    max-height: 200px; \n    overflow-y: auto; \n    \n  }\n  \n  .dropdown-content a {\n    color: black;\n    padding: 12px 16px;\n    text-decoration: none;\n    display: block;\n  }\n  \n  .dropdown-content a:hover {\n    background-color: #ddd;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
