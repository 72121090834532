const Footer = () => {
  return (
    <>
    <footer className="footer mt-auto py-3 uni-bg-blue uni-footer">
          <div className="container-fluid text-center">
            
          </div>
        </footer>
    </>
  );
};

export default Footer;
