import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { pages } from '../utilities/constants';
import './Header.css';

import { useEffect, useState } from 'react';
import logo from '../assets/img/logo.png';
import avatar from '../assets/img/avatar.svg';
import { useMsal } from '@azure/msal-react';
import { useAzureData } from '../context/AzureContext';

const Header = () => {
  const { instance } = useMsal(); 
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const { graphData, error } = useAzureData();

  useEffect(() => {
    const [pageinfo] = pages.filter((page) => page.path.includes(pathname));
    if(pageinfo){
    setActiveIndex(pageinfo.id - 1);
    }
  }, [pathname]);
  const handleClick = (index: any) => {
    setActiveIndex(index);
  };

  const logout = () => {
    try {
      const accounts = instance.getAllAccounts();
      const currentAccount = accounts[0];
      const logoutRequest = {
        account: currentAccount
      };
      instance.logoutRedirect(logoutRequest);
    } catch (err) {
      throw new Error('err');
    }
  };
  
  if(error) return <div>Something went wrong</div>
  return (
    <>
      <Navbar expand="lg" className="uni-bg-blue uni-header" variant="dark">
        <Container fluid>
          <Navbar.Brand href="/" className="unl-div-logo">
          <img src={logo} height={89} width={89} alt={'unilver logo'} />
            <div className="uni-brand-text">
              <span className="uni-header-primary">AI Foundation</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="align-center">
            <Nav
              as={'ul'}
              className="me-auto my-2 my-lg-0 list-container"
              style={{ gap: '10px', alignItems: 'center' }}
              navbarScroll>
              {pages.map((page) => (
                <Nav.Item
                  as="li"
                  key={page.id}
                  onClick={() => handleClick(page.id - 1)}>
                  <Nav.Link
                    as={Link}
                    className={`uni-nav-link ${activeIndex === page.id - 1 ? 'active' : ''}`}
                    to={`${page.path}`}>
                    {page.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <NavDropdown
              className="user-profile uni-bg-blue"
              align="end"
              title={
                <>
                  <span>{graphData?.['displayName']}</span>
                  <span>
                    <img src={avatar} alt="user icon" className="user-img rounded-circle" />
                  </span>
                </>
              }
              id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
