// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
  font-weight: 37.5rem;
  font-size: 1rem;
}

th,
tr {
  font-size: 1rem;
}

.card {
  background-color: #ffffff;
  border-radius: 0%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}



@media (max-width:600px) {
  .custom-card {
    width: 100%;
  }
}

@media (min-width:600px) {
  .custom-card {
    width: 50%;
  }
}

@media (min-width:768px) {
  .custom-card {
    width: 19.65%;
  }
}

.custom-card-row {
  column-gap: 5px;

}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,+CAA+C;;AAEjD;;;;AAIA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,eAAe;;AAEjB","sourcesContent":[".card-title {\n  font-weight: 37.5rem;\n  font-size: 1rem;\n}\n\nth,\ntr {\n  font-size: 1rem;\n}\n\n.card {\n  background-color: #ffffff;\n  border-radius: 0%;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n\n}\n\n\n\n@media (max-width:600px) {\n  .custom-card {\n    width: 100%;\n  }\n}\n\n@media (min-width:600px) {\n  .custom-card {\n    width: 50%;\n  }\n}\n\n@media (min-width:768px) {\n  .custom-card {\n    width: 19.65%;\n  }\n}\n\n.custom-card-row {\n  column-gap: 5px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
