import { useEffect, useState } from 'react';
import { Graph } from './Graph';

import { useAzureData } from '../context/AzureContext';
import {
  fetchApplicationsData,
  fetchRAIBatchMetrics,
  fetchRaiKPIs,
  fetchRaiModelStatus,
  fetchRAISafetyMetrics
} from '../service/apiServices';
import { errorMessages } from '../utilities/constants';
import PlaceHolder from './PlaceHolder';
import { formatter, isValidArray } from '../utilities/helper';

type PieChartDetails = {
  response: { labels: string[]; data: (number | null)[] };
  request: { labels: string[]; data: (number | null)[] };
};
type BarGraphDetails = {
  hallucination_metrics_xaxis: {
    month: string;
    values: Object;
  }[];
  hallucination_metrics_yAxis: string[];
  llmjudge_metrics_xaxis: {
    month: string;
    values: Object;
  }[];
  llmjudge_metrics_yAxis: string[];
};
type KPIs = {
  total_request_count: string;
  content_filter_count: string;
  hallucination_count: string;
  llm_judge_count: string;
  total_token_count: string;
  total_cost: string;
};
type App = {
  application_id: number;
  application_name: string;
};

type ModelStatus = {
  application_id: number;
  application_name: string;
  model_name: string;
  model_id: number;
  content_filter: string;
  hallucination: string;
  llm_as_a_judge: string;
};
const Rai = () => {
  let serialNumber = 0;
  const [dashboardData, setDashboardData] = useState<App[] | null>(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [KPIs, setKPIs] = useState<KPIs | null>(null);
  const [modelStatus, setModelStatus] = useState<ModelStatus[]>([]);
  const [isGraphView, setisGraphView] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [barGraphDetails, setBarGraphDetails] = useState<BarGraphDetails | null>(null);
  const [pieChartDetails, setPieChartDetails] = useState<PieChartDetails | null>(null);

  const { apiToken } = useAzureData();
  const fetchKPIs = async () => {
    try {
      const result = await fetchRaiKPIs({ appId: undefined }, apiToken);
      setKPIs(result);
    } catch (err: any) {
      setErrorMessage(err?.message);
    }
  };
  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const response = await fetchApplicationsData(apiToken);
        setDashboardData(response);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    const fetchModelStatus = async () => {
      try {
        const result = await fetchRaiModelStatus(apiToken);
        setModelStatus(result);
      } catch (err: any) {
        setErrorMessage(err?.message);
      }
    };

    fetchAppData();
    fetchKPIs();
    fetchModelStatus();
  }, [apiToken]);
  useEffect(() => {
    const fetchAppKpis = async () => {
      try {
        const result = await fetchRaiKPIs({ appId: selectedApp }, apiToken);
        setKPIs(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    const fetchBarGraphDetails = async () => {
      try {
       const result = await fetchRAIBatchMetrics({ appId: selectedApp }, apiToken);

       setBarGraphDetails(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    const fetchPieChartDetails = async () => {
      try {
        const result = await fetchRAISafetyMetrics({ appId: selectedApp }, apiToken);

        setPieChartDetails(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };

    if (selectedApp && selectedApp !== 'ALL') {
      fetchAppKpis();
      fetchPieChartDetails();
      fetchBarGraphDetails();
    } else if (selectedApp && selectedApp === 'ALL') {
      fetchKPIs();
    }
  }, [selectedApp]);

  const handleAppChange = (id: any) => {
    setSelectedApp(id);
    if (id === 'ALL') {
      setisGraphView(false);
    } else {
      setisGraphView(true);
    }
  };

  const renderBarGraph = (graphDetails: any) => {
    const isValidGraphDetails = Object.values(graphDetails).every((item: any) =>
      isValidArray(item)
    );
    if (graphDetails && isValidGraphDetails)
      return (
        <div className="row g-2 row-cols-1 row-cols-md-2 mb-3 text-center">
          <span>
            <Graph
              graphType="bar"
              labelText={'Hallucination Solution'}
              xData={graphDetails.hallucination_metrics_xaxis}
              yData={graphDetails.hallucination_metrics_yAxis}
            />
          </span>
          <span>
            <Graph
              graphType="bar"
              labelText={'LLM as a judge'}
              xData={graphDetails.llmjudge_metrics_xaxis}
              yData={graphDetails.llmjudge_metrics_yAxis}
            />
          </span>
        </div>
      );
  };
  const renderPieChart = (pieDetails: PieChartDetails) => {
    const { response, request } = pieDetails;
    const isValidResponse = Object.values(response).every((item) => isValidArray(item));
    const isValidRequest = Object.values(request).every((item) => isValidArray(item));

    return (
      <>
        <div className="row g-2 row-cols-1 row-cols-md-2 mb-3 text-center ">
          <div>
            {isValidRequest && (
              <span key={'Content Filter/ Safety Analysis For Request'}>
                <Graph
                  graphType="pie"
                  labelText={'Content Filter/ Safety Analysis For Request'}
                  yData={request.labels}
                  xData={request.data}
                />
              </span>
            )}
          </div>
          <div>
            {isValidResponse && (
              <span key={'Content Filter/ Safety Analysis For Response'}>
                <Graph
                  graphType="pie"
                  labelText={'Content Filter/ Safety Analysis For Response'}
                  yData={response.labels}
                  xData={response.data}
                />
              </span>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderGraphs = () => {
    return (
      <>
        {barGraphDetails && renderBarGraph(barGraphDetails)}
        {pieChartDetails && renderPieChart(pieChartDetails)}
      </>
    );
  };

  const renderTables = () => (
    <div className="row g-2 row-cols-1 row-cols-md-1 mb-3 text-center">
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm">
          <div className="card-header py-3">
            <h6 className="my-0 fw-normal">Service Used By Models Status</h6>
          </div>
          {modelStatus.length > 0 && (
            <div className="card-body table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '0.625rem' }}>#</th>
                    <th className="text-start">Application</th>
                    <th>Content Filter/ Safety</th>
                    <th>Hallucination</th>
                    <th>LLM as a Judge</th>
                  </tr>
                </thead>
                <tbody>
                  {modelStatus?.map((model, index) => (
                    <tr key={model.model_id+model.application_name}>
                      <td>{++serialNumber}</td>
                      <td className="text-start">{model.application_name}</td>
                      <td>
                        <span
                          className={`badge badge-pill ${
                            model.content_filter.toLowerCase() === 'yes'
                              ? 'bg-success'
                              : 'bg-danger'
                          } show-empty-badge`}></span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-pill ${
                            model.hallucination.toLowerCase() === 'yes' ? 'bg-success' : 'bg-danger'
                          } show-empty-badge`}></span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-pill ${
                            model.llm_as_a_judge.toLowerCase() === 'yes'
                              ? 'bg-success'
                              : 'bg-danger'
                          } show-empty-badge`}></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const renderCards = () => {
    if (KPIs) {
      return (
        <div className="mb-4">
          <div className="row g-1 d-flex custom-card-row" style={{ columnGap: '5px' }}>
            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">Total No. of Request</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['total_request_count'] !== null &&
                    formatter.format(parseInt(KPIs['total_request_count']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Request for Content Filter</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['content_filter_count'] !== null &&
                    formatter.format(parseInt(KPIs['content_filter_count']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Request for Hallucination</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['hallucination_count'] !== null &&
                    formatter.format(parseInt(KPIs['hallucination_count']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Request for LLM as a Judge</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['llm_judge_count'] !== null &&
                    formatter.format(parseInt(KPIs['llm_judge_count']))}
                </h1>
              </div>
            </div>

            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">No. of Tokens</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['total_token_count'] !== null &&
                    formatter.format(parseInt(KPIs['total_token_count']))}
                </h1>
              </div>
            </div>
            <div className="col card d-flex col-10 col-md-4 col-lg-2 custom-card justify-content-between ">
              <div className="row">
                <p className="card-title text-center">Token Cost</p>
              </div>
              <div className="row text-center">
                <h1>
                  {KPIs['total_cost'] !== null && '$' + parseFloat(KPIs['total_cost']).toFixed(2)}
                </h1>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  if (errorMessage) return <PlaceHolder message={errorMessages.serverError} />;

  return (
    <>
      <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <div className="mb-2 mb-md-0 float-end mx-2 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Application
          </p>
          <select
            defaultValue={'ALL'}
            className="form-select"
            style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
            onChange={(e) => handleAppChange(e.target.value)}>
            <option value="ALL">ALL</option>
            {dashboardData?.map((data) => (
              <option key={data.application_id} value={data.application_id}>
                {data.application_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {KPIs ? (
        renderCards()
      ) : (
        <div
          style={{ height: '100vh' }}
          className="container-fluid d-flex justify-content-center align-items-center">
          <h4 className="text-center">Loading...</h4>
        </div>
      )}
      {isGraphView ? renderGraphs() : renderTables()}
    </>
  );
};

export default Rai;
