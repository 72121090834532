// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown-btn {
  background-color: #eaeaea;
  color: #005eef;
  border: none;
  font-weight: 600;
  font-size: 0.875rem;
  font-family: 'Unilever Shilling', sans-serif;
}

.custom-dropdown-menu {
  /* width: inherit;
  min-width: unset;
  padding: inherit; */
  right: 0;
  left: auto
}

.profile-container {
  justify-content: flex-end;
  font-size: 16px;
  align-items: center;
  gap: 0.875rem;
  display: flex;
  height: 24px;
  width: 24px;
}
@media (min-width: 1300px) {
  .list-container {
    gap: 22px;
    padding-left: 15%;
    margin-right: 20%;
  }
 
}

@media(max-width:1300px){
  .uni-nav-link{
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,4CAA4C;AAC9C;;AAEA;EACE;;qBAEmB;EACnB,QAAQ;EACR;AACF;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,YAAY;EACZ,WAAW;AACb;AACA;EACE;IACE,SAAS;IACT,iBAAiB;IACjB,iBAAiB;EACnB;;AAEF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".custom-dropdown-btn {\n  background-color: #eaeaea;\n  color: #005eef;\n  border: none;\n  font-weight: 600;\n  font-size: 0.875rem;\n  font-family: 'Unilever Shilling', sans-serif;\n}\n\n.custom-dropdown-menu {\n  /* width: inherit;\n  min-width: unset;\n  padding: inherit; */\n  right: 0;\n  left: auto\n}\n\n.profile-container {\n  justify-content: flex-end;\n  font-size: 16px;\n  align-items: center;\n  gap: 0.875rem;\n  display: flex;\n  height: 24px;\n  width: 24px;\n}\n@media (min-width: 1300px) {\n  .list-container {\n    gap: 22px;\n    padding-left: 15%;\n    margin-right: 20%;\n  }\n \n}\n\n@media(max-width:1300px){\n  .uni-nav-link{\n    font-size: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
